<template>
    <div>
        <new-cloude-header></new-cloude-header>
        <div class="details-box">
            <div class="details-title">
                <div class="details-title-box">
                    <div class="title">
                        <h1>{{ title }}</h1>
                    </div>
                </div>
            </div>
            <div class="details-container-box">
                <div class="details-container">
                    <div class="details" v-html="details"></div>
                </div>
            </div>
        </div>
        <new-cloude-footer></new-cloude-footer>
    </div>
</template>

<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader.vue";
import NewCloudeFooter from "@/views/home/newCloudeFooter.vue";
import {dynamicDetails} from "@/api/newHomeApi";

export default {
    name: "detailsView",
    components: {NewCloudeFooter, NewCloudeHeader},
    data() {
        return {
            title: '',
            details: ''
        }
    },
    methods: {
        //     获取动态详情
        getDynamicDetails(id) {
            dynamicDetails({
                id: id,
                request: 'form'
            }).then(res => {
                if (res.code == 200) {
                    this.title = res.content.title
                    this.details = res.content.details

                }
            })
        }
    },
    created() {
        this.getDynamicDetails(this.$route.query.id)
    }
}
</script>

<style lang="scss" scoped>
.details-box {
  .details-title {
    background-color: #f1f1f1;

    .details-title-box {
      width: 1200px;
      margin: 0 auto;

      .title {
        padding: 60px 0;
      }
    }
  }

  .details-container-box {
    width: 1200px;
    margin: 0 auto;

    .details-container {
      padding: 30px 0;

      .details {
        font-size: 16px;
        color: #333;
        line-height: 1.8;
        text-align: justify;
      }
    }
  }
}
</style>